import $ from "jquery";

import * as kendo from "@progress/kendo-ui/js/kendo.grid.js";
import "@progress/kendo-ui/js/cultures/kendo.culture.fr-FR";
import "@progress/kendo-ui/js/messages/kendo.messages.fr-FR";

import Seed from "DMS/Seed/All";
import { mapToObject } from "DMS/Utils/Array";

declare global {
    interface Window {
        root: string;
        culture: string;
    }
}

class NewKendoController {
    constructor() {
        kendo.culture(window.culture);
    }

    generateList(targetSelector: string, moduleType: string, nomListe: string) {
        this.generateSearch();
        this.generateGrid(targetSelector, moduleType, nomListe);
    }

    generateSearch() {

    }

    generateGrid(targetSelector: string, moduleType: string, nomListe: string) {
        let list = new KendoList(moduleType, nomListe);
        list.build(targetSelector);
    }
}

export class KendoList {
    targetSelector: string = "";
    moduleType: string = "ServiceMaint";
    nomListe: string = "";

    constructor(moduleType: string, nomListe: string) {
        this.moduleType = moduleType;
        this.nomListe = nomListe;
    }

    async build(targetSelector: string) {
        this.targetSelector = targetSelector;

        let model = await this.getModel();

        let kendoParam = {
            dataSource: {
                data: await this.getData(),
                schema: model.schema,
                pageSize: this.getPageSize()
            },
            columnMenu: {
                filterable: true
            },
            editable: "incell",
            pageable: {
                pageSizes: [25, 50, 100],
                refresh: true
            },
            sortable: false,
            navigatable: true,
            resizable: true,
            reorderable: false,
            groupable: false,
            filterable: true,
            columns: model.columns
        };
        (<any>$(this.targetSelector)).kendoGrid(kendoParam);
    }

    async getModel(): Promise<KendoListDataModel> {
        return Seed.Ajax.Get({
            url: `${window.root}../../EManage/api/listes/columns/${this.moduleType}/${this.nomListe}`,
            json: true
        }).then((data) => {
            let listColumns = data as ApiListColumn[];

            return {
                schema: {
                    model: {
                        fields: {
                            ...mapToObject(listColumns, (column: ApiListColumn) => {
                                let value: { [key: string]: ApiListColumnType } = {};

                                value[`${column.nomTable}_${column.nomChamp}`] = {
                                    type: this.fromYInterfaceToKendoType(column.typeChamp).toString()
                                };

                                return value;
                            })
                        }
                    }
                },
                columns: listColumns.map((column) => {
                    return {
                        field: `${column.nomTable}_${column.nomChamp}`,
                        title: column.label,
                        width: 300
                    };
                }),
            } as KendoListDataModel;
        });
    }

    getPageSize(): number {
        return 13;
    }

    async getData(): Promise<any> {
        return [
            {
                "dossier_numdossier": "Bonjour",
                "dossier_clos": true,
            }
        ];
    }

    fromYInterfaceToKendoType(typeChamp: InterfaceType): KendoType {
        switch (typeChamp) {
            case InterfaceType.Nombre:
                return KendoType.Number;
            case InterfaceType.Checkbox:
                return KendoType.Boolean;
            case InterfaceType.Textbox:
            default:
                return KendoType.String;
        }
    }
}

enum InterfaceType {
    Textbox = "textbox",
    Checkbox = "checkbox",
    Textarea = "textarea",
    Date = "date",
    Select = "select",
    Section = "section",
    Button = "button",
    Dynlist = "textarea",
    Nombre = "nombre",
    Tel = "tel",
    Mail = "mail",
    DateHeure = "dateheure",
    Fichier = "fichier",
    Pourcentage = "pourcentage",
    Couleur = "couleur",
    SelectChamp = "selectchamp",
    Php = "php",
    Http = "http",
    Signature = "signature",
    Radio = "radio",
    Export = "export",
    MultiSelect = "multiselect",
    Password = "password",
    ColorPicker = "textarea",
    Heure = "heure",
    AjaxList = "ajaxlist",
    Vide = "",
}

enum KendoType {
    String = "string",
    Boolean = "boolean",
    Number = "number"
}

interface ApiListColumn {
    nomTable: string;
    nomChamp: string;
    label: string; // @todo : gettext js

    typeChamp: InterfaceType;
}

interface ApiListColumnType {
    type: string;
}

interface KendoDataSource {
    model: {
        fields: any;
    }
}

interface KendoColumn {
    field: string;
    title: string;
    width: number;
}

interface KendoListDataModel {
    columns: KendoColumn[];
    schema: KendoDataSource;
}

export default new NewKendoController();