import "zone.js";

import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "DMS/Interfaces/app.module";
import { enableProdMode, Injector, PlatformRef } from "@angular/core";

class AngularController {
	started : boolean = false;
	plaform : PlatformRef;
	injector : Injector;

	async bootstrapIfNeeded() {
		if(!this.started) {
			/*if (process.env.NODE_ENV.production) {
				enableProdMode();
			}*/
			
			this.plaform = platformBrowserDynamic();
			return this.plaform.bootstrapModule(AppModule).catch(err => console.error(err)).then(() => {
				this.injector = this.plaform.injector;
				this.started = true;
			});
		}
	}
}

export default new AngularController();
