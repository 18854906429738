import Seed from "DMS/Seed/All";
import { closeModalIframe } from "DMS/Utils/modal";
import { isSmallDevice } from "DMS/Utils/checkFeatures";
import SignaturePad from "signature_pad";
import * as bootbox from "bootbox";

export class SignatureController
{
    canvas: HTMLCanvasElement;
    signaturePad: SignaturePad;

    /**
     * Signature update
     * @param {int} bbmindex Index de l'element
     * @param {type} uniqId msgSuccess's zone identifier to receive the message 'ok'
     * @param {string} nomChamp Fieldname to update
     * @param {string} liste Listname to update
     */
    public async callSignatureUpdate(bbmindex: number, uniqId, nomChamp: string, liste: string) {
        const output = this.signaturePad.toData();
        const encodedPng = !this.signaturePad.isEmpty() ? this.signaturePad.toDataURL() : undefined;
        return Seed.Ajax.Post({
            url: "_module/signature/SignatureJqueryUpdate.php",
            data: "output=" + JSON.stringify(output)
                + "&encodedpng=" + encodedPng
                + "&bbmindex=" + bbmindex
                + "&nomChamp=" + nomChamp
                + "&liste=" + liste,
            async: true,
            json: false,
        }).then((xml) => {
            if (isSmallDevice()) {
                Seed.Tab.Reload();
            }
        }).catch((err) => {
            $("#msgError_signature_" + uniqId).text(err).show();
        });
    }

    /**
     * Mise à jour du symbole décrivant l'existence ou non d'une signature
     * @param {int} bbmindex Index de l'element
     */
    public signCheck(bbmindex: number, liste: string) {
        const myFrame = Seed.Tab.GetFrame().find("#alreadyDone");
        if (myFrame.length !== 0) {
            myFrame.toggleClass();
            Seed.Ajax.Post({
                url: "_module/SignCheck.php",
                data: "bbmindex=" + bbmindex
                + "&liste=" + liste,
                json: false,
                async: true,
                success: function (result) {
                    if (result === "0") {
                        myFrame.addClass("fa fa-times");
                    }
                    Seed.Tab.Reload();
                }
            });
        }
    }

    /**
     * Automatic close of the modal on signature saving
     */
    public closeModalSignature() {
        closeModalIframe();
    }

    private deleteSignature(result, id, bbm, liste)
    {
        const sigPad = this.signaturePad;
        const aSelector = Seed.Tab.GetFrame().find('#deleteOneLink');
        aSelector.removeClass();
        if (result) {
            sigPad.clear();
            this.callSignatureUpdate(
                bbm,
                'signature_' + id,
                'signature',
                liste
            );
        }
    }

     public callSignature(message, id, bbm, liste) {
        bootbox.confirm(
            message,
            (result) => this.deleteSignature(result, id, bbm, liste)
        );
    }

    public initializeSignature(signature, id): void {
        const options = {};
        this.canvas = document.querySelector('canvas#pad' + id + '.pad');
        // If we have to regenerate a sigPag, unbind all event listeners
        if (this.signaturePad !== undefined) {
            this.signaturePad.off();
        }
        this.signaturePad = new SignaturePad(this.canvas, options);

        if (signature !== '') {
            const parsedSignature = JSON.parse(signature);
            if (Array.isArray(parsedSignature) && parsedSignature.length > 0) {
                if (parsedSignature[0].hasOwnProperty('color')) { // new format
                    this.signaturePad.clear();
                    this.signaturePad.fromData(parsedSignature);
                }
            }
        }
    }
}
